.how-team {
    padding: 70px 0 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: $breakpoint-mid) {
        flex-direction: column;
    }
    @media (max-width: $breakpoint-mid) {
        padding-top: 50px;
    }
    @media (max-width: $breakpoint-small) {
        padding-bottom: 30px;
    }
    &__text {
        width: span(6);
        @media (max-width: $breakpoint-mid) {
            width: 100%;
            margin-bottom: 25px;
        }
    }
    &__title {
        margin: 0 0 25px;
    }
    &__body {
        margin-bottom: 20px;
    }
    &__body,
    &__points {
        padding-right: 2em;
    }
    &__points {
        columns: 2;
        @media (max-width: $breakpoint-small) {
            columns: 1;
        }
    }
    &__photos {
        width: 50%;
        @media (max-width: $breakpoint-mid) {
            width: 75%;
            align-self: center;
        }
        @media (max-width: $breakpoint-small) {
            width: 100%;
        }
        &__left,
        &__right {
            width: 50%;
            float: left;
            padding: 10px;
        }
        &__left {
            .how-team__photo:nth-child(2) {
                padding-left: 25px;
            }
        }
    }
    &__photo-actual {
        margin-bottom: 20px;
    }
}
