html {
    box-sizing: border-box;
}

*,
*:after,
*:before {
    box-sizing: inherit;
}

body {
    @extend .t-body;
    color: $color-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    width: 100%;
    overflow-x: hidden;
}

img {
    @include responsive-image;
    display: block;
}

a {
    color: $color-brand;
    text-decoration: none;
}

ol,
ul,
li,
dl,
dt,
dd {
    list-style: none;
    padding: 0;
    margin: 0;
}

h1 {
    @extend .t-h1;
}

h2 {
    @extend .t-h2;
}

h3 {
    @extend .t-h3;
}
