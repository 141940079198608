.staggered-gallery {
    $block: &;
    overflow: hidden;
    width: 100%;
    display: block;
    &__left-column {
        width: span(5, false);
        float: left;
        padding-right: 10px;
        @media (max-width: $breakpoint-small) {
            padding-right: 3px;
            padding-top: 14%;
        }
    }
    &__right-column {
        width: span(7, false);
        float: right;
        padding-left: 10px;
        @media (max-width: $breakpoint-small) {
            padding-left: 3px;
        }
    }
    &__photo {
        margin-bottom: 20px;
        @media (max-width: $breakpoint-small) {
            margin-bottom: 6px;
        }
    }
    &__sub-grid {
        #{$block}__photo {
            &:nth-child(2) {
                width: #{100% / 7 * 4};
                float: left;
                padding-right: 10px;
                @media (max-width: $breakpoint-small) {
                    padding-right: 3px;
                    width: 90%
                }
            }
            &:nth-child(3) {
                width: #{100% / 7 * 3};
                float: right;
                padding-left: 10px;
                @media (max-width: $breakpoint-small) {
                    display: none;
                }
            }
        }
    }
}
