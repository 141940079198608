.dropdown {
    display: none;
    width: 118px;
    background: #F6F6F6;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.17);
    line-height: 29px;
    position: fixed;
    @media (max-width: $breakpoint-mid) {
        display: block;
        display: flex;
        margin-bottom: 1.5em;
        margin-top: 0px;
        box-shadow: none;
        .has-mobile-nav & {
            opacity: 1;
            pointer-events: auto;
            z-index: 100;
            * {
                pointer-events: auto;
            }
        }
    }

    &__label {
        position: relative;
        display: block;
        left: 8%;
        font-size: 13.7944px;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #3B3B3A;
        &:after {
            transition: all .2s ease-in-out;
            content: '';
            display: block;
            transform: translateY(3px);
            width: 100%;
            height: 12px;
            background: $color-brand;
            opacity: 0;
            position: absolute;
            bottom: -4px;
            z-index: -1;
        }
        @media (max-width: $breakpoint-mid) {
            display: block;
            line-height: 10px;
            padding-top: 0px;
            width: 100%;
            &:after {
                bottom: 0px;
                height: 0px;
            }
        }
    }

    &__item {
        display: block;
        padding-top: 7px;
        padding-bottom: 5px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
        @media (max-width: $breakpoint-mid) {
            line-height: 0px;
            padding-top: -10px;
            &:hover {
                :after {
                    opacity: .5;
                    transform: translateY(0px);
                }
            }
        }
    }

}