.l-container {
    max-width: $layout-max-width;
    margin: 0 auto;
    position: relative;
    padding: {
        left: $layout-gutter / 2;
        right: $layout-gutter / 2;
    }
    @media (max-width: $breakpoint-mid) {
        padding: {
            left: 33px;
            right: 33px;
        }
    }
}

.l-gutters {
    margin: {
        left: $layout-gutter / 2;
        right: $layout-gutter / 2;
    }
}

.l-vertical-gutters {
    padding: {
        top: $layout-gutter / 2;
        bottom: $layout-gutter / 2;
    }
}

.l-row {
    @include clearfix;
    @include makeup-gutters;
}

.l-columns {
    float: left;
    @for $i from 1 through $layout-columns {
        &--#{$i} {
            @include span($i);
        }
    }
}

.l-margin {
    @include spacing(margin);
    &--less {
        @include spacing(margin, both, less);
    }
    &--more {
        @include spacing(margin, both, more);
    }
    &--top {
        @include spacing(margin, top);
        &--less {
            @include spacing(margin, top, less);
        }
        &--more {
            @include spacing(margin, top, more);
        }
    }
    &--bottom {
        @include spacing(margin, bottom);
        &--less {
            @include spacing(margin, bottom, less);
        }
        &--more {
            @include spacing(margin, bottom, more);
        }
    }
}

.l-padding {
    @include spacing(padding);
    &--less {
        @include spacing(padding, both, less);
    }
    &--more {
        @include spacing(padding, both, more);
    }
    &--top {
        @include spacing(padding, top);
        &--less {
            @include spacing(padding, top, less);
        }
        &--more {
            @include spacing(padding, top, more);
        }
    }
    &--bottom {
        @include spacing(padding, bottom);
        &--less {
            @include spacing(padding, bottom, less)
        }
        &--more {
            @include spacing(padding, bottom, more)
        }
    }
}

.l-padded {
    width: span(9.5);
    margin: 0 auto;
    @media (max-width: $breakpoint-small) {
        width: span(11);
    }
}

.l-mid-full {
    @media (max-width: $breakpoint-mid) {
        width: 100%;
        padding: 0;
    }
}

.l-small-full {
    @media (max-width: $breakpoint-small) {
        width: 100%;
        padding: 0;
    }
}

.l-narrow {
    width: span(8);
    margin: 0 auto;
    @media (max-width: $breakpoint-mid) {
        width: 100%;
    }
    @media (max-width: $breakpoint-small) {
        padding-top: 1em;
    }
}
