.form-element {
    $block: &;
    padding: 15px 0;
    &--checkbox {
        display: flex;
        align-items: center;
    }
    &__label {
        transition: all .3s $easeInOutQuad;
        display: inline-block;
        color: $color-base;
        opacity: .5;
        font-size: 18px;
        #{$block}--placeholder-label & {
            transform-origin: left;
            transform: translateY(26px);
        }
        #{$block}--checkbox & {
            color: $color-base;
            opacity: 1;
            font-size: 16px;
            line-height: 1.3;
        }
        .has-focus &,
        .has-value & {
            transform: translate(0) scale(.8);
        }
    }
    &__input {
        #{$block}--checkbox & {
            margin-right: 20px;
        }
    }
}
