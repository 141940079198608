.outline {
    counter-reset: outline-counter;
    margin-bottom: 40px;
    &__item {
        counter-increment: outline-counter;
        @media (max-width: $breakpoint-mid) {
            padding-left: 50px;
        }
        @media (max-width: $breakpoint-small) {
            padding-left: 0px;
        }
    }
    .outline-item__title {
        @include counter(outline-counter);
        &:after {
            top: .65em;
            @media (max-width: $breakpoint-mid) {
                top: .6em;
            }
        }
    }
}
