.t-h1 {
    font-size: 120px;
    font-family: $font-feature;
    line-height: 136px;
    @media (max-width: $breakpoint-mid) {
        font-size: 80px;
        line-height: 88px;
    }
}

.t-h2 {
    font-size: 44px;
    line-height: 48px;
    font-weight: $font-weight-medium;
    @media (max-width: $breakpoint-mid) {
        font-size: 32px;
        line-height: 1.25em;
        letter-spacing: .1px;
    }
}

.t-h3 {
    font-size: 28px;
    line-height: 32px;
    font-weight: $font-weight-medium;
}

.t-accent {
    font-family: $font-accent;
}

.t-large-body {
    font: {
        family: $font-base;
        weight: $font-weight-book;
        size: 18px;
    }
    line-height: 1.45;
}

.t-body {
    font: {
        family: $font-base;
        weight: $font-weight-book;
        size: $font-size-body;
    }
}

