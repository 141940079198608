.callout {
    $block: &;
    text-align: center;
    padding: 80px 0 40px;
    @media (max-width: $breakpoint-small) {
        padding: 45px 0 60px;
    }
    &__cta {
        padding: 24px 0;
        @media (max-width: $breakpoint-mid) {
            padding-bottom: 0;
        }
        .button {
            @media (max-width: $breakpoint-small) {
                display: block;
            }
        }
    }
    .headline {
        width: 82%;
        margin: 0 auto;
        padding: {
            top: 0;
            bottom: 0;
        }
        @include mid-full;
    }
    &--full .headline {
        width: 100%;
    }
}
