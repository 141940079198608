@mixin clearfix {
    &:after {
        content: '.';
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
        position: absolute;
    }
}

@mixin hover-focus-underline {
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

@mixin responsive-image {
    max-width: 100%;
    height: auto;
}

@mixin full-width {
    width: 100%;
    float: none;
    clear: both;
}

@mixin small-full {
    @media (max-width: $breakpoint-small) {
        @include full-width;
    }
}

@mixin mid-full {
    @media (max-width: $breakpoint-mid) {
        @include full-width;
    }
}

@mixin accessible-hide {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

@mixin accessible-unhide {
    position: relative;
    left: auto;
    width: auto;
    height: auto;
    overflow: visible;
}

@function span($columns, $gutter: true, $total-columns: $layout-columns) {
    $width: 100% / $layout-columns * $columns;
    @if ($gutter) {
        @return calc(#{$width} - #{$layout-gutter});
    } @else {
        @return $width;
    }
}

@function image-url($image) {
  @return url($path-image + $image);
}

@mixin gutters {
  margin: {
    left: $layout-gutter / 2;
    right: $layout-gutter / 2;
  }
}

@mixin span($columns) {
  width: span($columns);
}

@mixin makeup-gutters {
  width: calc(100% + #{$layout-gutter});
  margin-left: -#{$layout-gutter / 2};
}

@mixin spacing($type: margin, $side: both, $amount: normal) {
    $space: 1.0em;

    @if $amount == less {
        $space: 1em;
    } @else if $amount == more {
        $space: 100px;
    } @else if $amount == normal {
        $space: 55px;
    } @else {
        $space: $amount;
    }

    @if $side == both {
        #{$type}: {
            top: $space;
            bottom: $space;
        }
    } @else if $side == top {
        #{$type}: {
            top: $space;
            bottom: 0;
        }
    } @else if $side == bottom {
        #{$type}: {
            top: 0;
            bottom: $space;
        }
    }
}

@mixin counter($counter) {
    position: relative;
    &:before {
        content: counter($counter, decimal-leading-zero);
        position: absolute;
        top: 1px;
        left: -70px;
        font: {
            family: $font-feature;
            size: 12px;
        }
        color: #767676;
        letter-spacing: .7px;
        @media (max-width: $breakpoint-mid) {
            display: none;
        }
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: .6em;
        left: -48px;
        width: 36px;
        height: 3px;
        background: #767676;
        @media (max-width: $breakpoint-mid) {
            display: none;
        }
    }
}
