.photo-stack {
    $block: &;
    display: flex;
    position: relative;
    flex-direction: column;
    &__item {
        &:nth-of-type(1) {
            width: 80.5%;
            align-self: flex-end;
        }
        &:nth-of-type(2) {
            width: 48.5%;
            position: absolute;
            bottom: -60px;
        }
    }
}
