.case-study-feature {
    $block: &;
    display: flex;
    @media (max-width: $breakpoint-mid) {
        flex-direction: column;
    }
    &__title {
        @extend .t-h3;
        margin: 10px 0;
        @media (max-width: $breakpoint-mid) {
            font-size: 24px;
        }
    }
    &__description {
        font-size: 18px;
        line-height: 1.56em;
        letter-spacing: .1px;
        margin-bottom: 10px;
    }
    &__body {
        width: 50%;
        margin-left: span(1);
        position: relative;
        z-index: 3;
        padding: 2em 0;
        @media (max-width: $breakpoint-mid) {
            width: 100%;
            margin-left: 0;
            padding: 25px 1em 0 60px;
        }
    }
    &__imagery {
        width: span(6);
        position: relative;
        @media (max-width: $breakpoint-mid) {
            width: 100%;
            padding: 1em 1em 0 0;
        }
    }
    &__foreground {
        position: absolute;
        bottom: 1em;
        left: 0;
        z-index: 2;
        width: calc(100% - #{$layout-gutter});
        @media (max-width: $breakpoint-mid) {
            position: relative;
            left: 20px !important;
            bottom: 5px !important;
            width: calc(100% - 20px);
        }
        &--center {
            width: auto;
            max-height: calc(100% + 30px);
            top: auto;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            @media (max-width: $breakpoint-mid) {
                left: 50% !important;
            }
        }
    }
    &__background {
        height: 100%;
        position: relative;
        z-index: 1;
        @media (max-width: $breakpoint-mid) {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 100%;
            width: calc(100% - 60px);
            transform: none !important;
        }
    }
    &__photo {
        width: 100%;
        height: 100%;
        &-actual {
            width: auto;
            max-height: 100%;
            float: right;
        }
        @media (max-width: $breakpoint-mid) {
            width: calc(100% + 1em);
        }
    }


    // One-off adjustments for specific case studies
    &--hootsuite {
        #{$block} {
            &__body {
                width: 47.5%;
                @include mid-full;
            }
            &__imagery {
                width: 50%;
                @include mid-full;
            }
            &__background {
                width: 101.8%;
                @media (max-width: $breakpoint-mid) {
                    width: calc(100% - 60px);
                }
            }
            &__description {
                padding-right: 1em;
            }
        }
    }
    &--modo {
        #{$block} {
            &__body {
                width: 38%;
                margin-right: calc(8.33% - 8px);
                @include mid-full;
            }
            &__imagery {
                width: 51.6%;
                @include mid-full;
            }
            &__foreground {
                @media (max-width: $breakpoint-mid) {
                    width: 50%;
                    position: absolute;
                    bottom: 0 !important;
                }
            }
            &__photo {
                @media (max-width: $breakpoint-mid) {
                    overflow: hidden;
                    &-actual {
                        margin-bottom: -4em;
                    }
                }
            }
        }
    }
    &--luvo {
        @media (max-width: $breakpoint-mid) {
            padding-top: 20px;
        }
        #{$block} {
            &__body {
                width: 44%;
                margin-left: 2%;
                @include mid-full;
                @media (max-width: $breakpoint-mid) {
                    margin-left: 0;
                }
            }
            &__imagery {
                width: 64%;
                @include mid-full;
            }
            &__foreground {
                width: 90%;
                @media (max-width: $breakpoint-mid) {
                    width: calc(100% - 20px);
                }
            }
            &__background {
                transform: translate(9%, -10%);
                height: 103%;
                bottom: -10%;
                @media (max-width: $breakpoint-mid) {
                    width: calc(100% - 60px);
                }
            }
        }
    }
    &--mojio {
        padding: 2em 0 ;
        @media (max-width: $breakpoint-mid) {
            padding: 0;
        }
        #{$block} {
            &__foreground {
                width: 66.5%;
                bottom: 0;
            }
            &__background {
                width: 119.5%;
                left: -1px;
                height: 100%;
                bottom: 0;
                @media (max-width: $breakpoint-mid) {
                    width: calc(100% - 60px);
                    left: auto;
                    right: 0;
                    bottom: 0;
                    height: 92%;
                }
            }
        }
    }
    &--how {
        #{$block} {
            &__body {
                width: calc(50% - 30px);
                padding-right: 3em;
                @include mid-full;
            }
            &__photo {
                padding-left: 15%;
                @media (max-width: $breakpoint-mid) {
                    padding-left: 0;
                    height: 0;
                    overflow: hidden;
                    padding-bottom: 60%;
                    &-actual {
                        max-height: initial;
                        margin-top: -20%;
                        width: 100%;
                    }
                }
            }
        }
    }
}
