.case-study-intro {
    padding: 60px 0 120px;
    text-align: center;
    background-color: #F6F6F6;
    @media (max-width: $breakpoint-mid) {
        padding: 60px 0;
    }
    @media (max-width: $breakpoint-small) {
        padding: 40px 0 60px;
    }
    &__logo {
        display: flex;
        justify-content: center;
        margin-bottom: 60px;

        > img {
            &.logo-mojio {
                height: 72px;
            }

            &.logo-modo {
                height: 80px;
            }

            &.logo-luvo {
                height: 48px;
            }

            &.logo-d-wave {
                height: 64px;
            }
        }
    }
    &__divider {
        display: block;
        width: 60px;
        height: 2px;
        margin: 0 auto 30px;
    }
    &__title {
        font-size: 28px;
        font-weight: $font-weight-medium;
        @media (max-width: $breakpoint-small) {
            font-size: 22px;
        }
    }
}
