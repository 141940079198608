.signup {
    padding-bottom: 1.5em;
    &__form {
        position: relative;
    }
    &__input {
        color: #d8d8db;
        width: 100%;
        padding-right: 7em;
        font-size: 14px;
        padding: 9px 10px;
        border: 1px solid #555;
        border-radius: 2px;
        background: none;
        &:focus {
            outline: 0;
        }
        &::placeholder {
            color: #d8d8db;
        }
    }
    &__submit {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        background: #555;
        border-radius: 0 3px 3px 0;
        border: none;
        padding: {
            left: 10px;
            right: 10px;
        }
    }
    &__message {
        font-size: 14px;
        line-height: 1.2;
        position: absolute;
        a {
            @include hover-focus-underline;
        }
        &--error {
            color: $color-brand;
            a {
                color: $color-brand;
            }
        }
    }
}
