.logo-pool {
    display: table;
    @media (max-width: $breakpoint-mid) {
        display: flex;
        flex-flow: wrap;
    }
    &__item {
        display: table-cell;
        vertical-align: middle;
        padding-right: 40px;
        @media (max-width: $breakpoint-mid) {
            display: block;
            width: 50%;
            padding: 33px 10px;
        }
        &:last-child {
            padding-right: 0;
        }
    }
    &__logo {
        max-height: 100%;
        max-width: 95px;
        max-height: 46px;
        @media (max-width: $breakpoint-mid) {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
        &--toyota {
            @media (min-width: $breakpoint-mid + 1) {
                height: 30px;
            }
        }
    }
}
