.active-button {
    $block: &;
    position: relative;
    overflow: hidden;
    &__spinner {
        transition: all .5s $easeInOutQuart;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -200%);
        #{$block}.is-active & {
            transform: translate(-50%, -50%);
        }
    }
    &__label {
        transition: all .5s $easeInOutQuart;
        position: relative;
        display: block;
        #{$block}.is-active & {
            transform: translateY(200%);
        }
    }
}
