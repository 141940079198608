.capability {
    &__cta {
        text-align: center;
    }
    .capabilities-hero__image {
        max-width: 761.87px;
        @media (max-width: $breakpoint-mid) {
            max-width: 330px;
        }
    }
    .capability-hero {
        .canvas{
            padding-bottom: 75px;
            padding-top: 85px;
        }
    }
}