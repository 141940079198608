.hero.hero-with-gallery {
    $block: &;
    .canvas {
        padding-bottom: 0;
    }
    .hero__inner {
        overflow: hidden;
        padding-bottom: 20.3%;
        @media (max-width: $breakpoint-small) {
            padding-bottom: 28%;
        }
    }
    .hero__title {
        width: 49%;
        margin-bottom: 0;
        @include mid-full;
        @media (max-width: $breakpoint-mid) {
            font-size: 80px;
        }
        @media (max-width: $breakpoint-small) {
            font-size: 60px;
        }
    }
    .hero__description {
        width: 40.5%;
        padding-top: 76px;
        float: right;
        position: relative;
        &:before {
            width: 60px;
            height: 3px;
            margin-bottom: 25px;
        }
        @include mid-full;
        @media (max-width: $breakpoint-mid) {
            padding-top: 40px;
        }
        @media (max-width: $breakpoint-small) {
            margin-top: 0;
        }
    }
    .hero__gallery {
        margin-top: -15%;
        padding-bottom: 5%;
        @media (max-width: $breakpoint-small) {
            margin-top: -22%;
        }
    }
}
