.checkbox {
    &__input {
        position: relative;
        &:focus {
            outline: 0;
        }
        &:before {
            visibility: visible;
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border: 2px solid $color-brand;
            border-radius: 50%;
            background: $color-canvas;
            pointer-events: none;
            position: absolute;
            top: -2px;
            left: -2px;
        }
        &:checked:before {
            background: rgba($color-brand, 1);
        }
        &:active:before {
            background: #eaa7a7;
        }
    }
}

.careers-form .checkbox {
    &__input {
        position: relative;
        &:focus {
            outline: 0;
        }
        &:before {
            visibility: visible;
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border: 2px solid $color-brand;
            border-radius: 50%;
            background: #F3F3F4;
            pointer-events: none;
            position: absolute;
            top: -2px;
            left: -2px;
        }
        &:checked:before {
            background: rgba($color-brand, 1);
        }
        &:active:before {
            background: #eaa7a7;
        }
    }
}