.headline {
    padding: 75px span(1, false);
    text-align: center;
    @media (max-width: $breakpoint-small) {
        padding: 30px 0;
    }
    &__title {
        margin: 0;
        font: {
            family: $font-feature;
            size: 44px;
        }
        line-height: 1.18em;
        @media (max-width: $breakpoint-mid) {
            font-size: 32px;
        }
        @media (max-width: $breakpoint-small) {
            font-size: 25px;
        }

        &__capability {
            margin: 0;
            font: {
                family: $font-feature;
                size: 44px;
            }
            line-height: 1.18em;
            
            @media (max-width: $breakpoint-small) {
                padding-top: 30px;
                font-size: 28px;
                text-align: center;
            }
            
        }
    }
    &__subtitle {
        @extend .t-accent;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 2em;
    }
    &__description {
        @extend .t-large-body;
        margin-top: 25px;
    }
}
