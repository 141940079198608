.icon-tile {
    $block: &;
    width: 220px;
    height: 280px;
    position: relative;
    background: #000;
    @media (max-width: $breakpoint-small) {
        height: 200px;
    }
    &__image {
        transition: opacity .35s $easeInOutCirc;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        opacity: .6;
        #{$block}:hover & {
            opacity: 1;
        }
    }
    &__icon {
        transition: transform .35s $easeInOutCirc;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        background: #000;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        #{$block}:hover & {
            transform: translate(-50%, -50%) scale(1.2);
        }
    }
    &__icon-svg {
        fill: white;
        width: 25px;
        height: 25px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
