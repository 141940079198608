.list {
    $block: &;
    font-size: 18px;
    line-height: 1.25em;
    font-weight: $font-weight-medium;
    &--small {
        font-size: 14px;
        font-weight: normal;
        @media (max-width: $breakpoint-mid) {
            font-size: 18px;
            font-weight: 500;
        }
    }
    &__item {
        margin-bottom: .5em;
        padding-left: 15px;
        position: relative;
        &:before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background: $color-canvas;
            border: 2px solid $color-brand;
            border-radius: 50%;
            position: absolute;
            top: 8px;
            left: 0;
            #{$block}--small & {
                top: 5px;
            }
        }
    }
}
