.close {
    height: 30px;
    width: 30px;
    position: relative;
    cursor: pointer;
    &:before,
    &:after {
        content: '';
    }
    &:before,
    &:after {
        background: #555;
        height: 2px;
        border-radius: 1.5px;
        width: 100%;
        position: absolute;
        left: 0;
        top: 50%
    }
    &:before {
        top: 0;
        transform: rotate(45deg) translate(11px, 10px);
    }
    &:after {
        bottom: 0;
        transform: rotate(-45deg);
    }
    &:focus {
        outline: 0;
    }
}
