.photo-strew {
    $block: &;
    display: flex;
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: -5%;
        left: 9%;
        background: #f1f1f1;
        height: 112%;
        width: 73.5%;
        transform: rotate(6deg);
        @media (max-width: $breakpoint-mid) {
            display: none;
        }
    }
    &__photo {
        padding: 10px;
        width: 100%;
        height: auto;
        position: relative;
        z-index: 2;
    }
    &__left-column {
        width: 57%;
        text-align: right;
        #{$block}__photo {
            display: inline-block;
            &:nth-of-type(2) {
                max-width: 80%;
            }
        }
    }
    &__right-column {
        width: 45.5%;
        #{$block}__photo {
            padding-top: 18%;
        }
    }
}
