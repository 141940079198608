.circled-icon {
    width: 120px;
    height: 120px;
    background: #f4f4f5;
    border-radius: 50%;
    position: relative;
    &__icon-svg {
        fill: $color-brand;
        max-width: 48px;
        max-height: 48px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
