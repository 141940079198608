.outline-item {
    display: flex;
    flex-direction: row;
    padding: 45px 0;
    @media (max-width: $breakpoint-mid) {
        padding: 30px 0px;
    }
    &__icon {
        width: 25%;
        padding-top: 12px;
        @media (max-width: $breakpoint-mid) {
            width: 2%;
        }
    }
    &__image {
        width: 25%;
        padding-top: 12px;
        text-align: center;

        @media (max-width: $breakpoint-mid) {
            width: 2%;
        }
    }
    &__text {
        width: 75%;
        @media (max-width: $breakpoint-mid) {
            width: 100%;
        }
    }
    &__points {
        width: 25%;
        @media (max-width: $breakpoint-small) {
            width: 100%;
        }
    }
    &__description {
        width: 75%;
        padding-right: 5em;
        @media (max-width: $breakpoint-small) {
            width: 100%;
            margin-bottom: 18px;
            padding-right: 0;
        }
    }
    &__title {
        margin: 0 0 10px;
        margin-right: 250px;
        line-height: 34px;
        font: {
            family: $font-feature;
            size: 28px;
        }
        @media (max-width: $breakpoint-mid) {
            margin-top: 150px;
            margin-right: 10px;
            font-family: $font-base;
            font-weight: 28px;
        } 
    }
    &__body {
        display: flex;
        flex-direction: row;
        @media (max-width: $breakpoint-small) {
            flex-direction: column;
        }
    }
}
