.hero {
    $block: &;
    padding-bottom: 5em;
    padding-top: 60px;
    @media (max-width: $breakpoint-mid) {
        padding-top: 40px;
    }
    @media (max-width: $breakpoint-small) {
        padding-top: 0;
    }
    &--reverse {
        @extend .u-reverse;
    }
    &--dark {
        padding: 0;
        .canvas {
            padding: 60px 0;
        }
    }
    &__title {
        @extend .t-h1;
        font-size: 100px;
        line-height: 1.15em;
        margin-top: 0;
        margin-bottom: .4em;
        @media (max-width: $breakpoint-mid) {
            font-size: 80px;
        }
        @media (max-width: $breakpoint-small) {
            font-size: 60px;
        }
        &--long {
            font-size: 48px;
            @media (max-width: $breakpoint-mid) {
                font-size: 60px;
            }
            @media (max-width: $breakpoint-small) {
                font-size: 38px;
            }
        }
        #{$block}--row & {
            float: left;
            margin-bottom: 0;
        }
    }
    &__description {
        font-size: 20px;
        line-height: 36px;
        @media (max-width: $breakpoint-mid) {
            font-size: 24px;
            line-height: 36px;
        }
        @media (max-width: $breakpoint-small) {
            font-size: 20px;
            line-height: 1.4em;
        }
        &:before {
            content: '';
            display: block;
            width: 40px;
            height: 2px;
            background: $color-brand;
            margin-bottom: 30px;
        }
        #{$block}--even-columns & {
            padding-right: 3em;
        }
    }
    &__text {
        #{$block}--column & {
            width: 57%;
            @include mid-full;
        }
        #{$block}--even-columns & {
            width: 50%;
            @include mid-full;
        }
    }
    &__inner {
        display: flex;
        #{$block}--column & {
            @media (max-width: $breakpoint-mid) {
                flex-direction: column;
            }
        }
    }
    &__gallery {
        #{$block}--column & {
            width: span(5, false);
            padding-left: 15px;
            @media (max-width: $breakpoint-mid) {
                padding-top: 40px;
                padding-left: 0;
                width: span(8, false);
            }
            @media (max-width: $breakpoint-small) {
                width: 100%;
            }
        }
        #{$block}--even-columns & {
            width: 50%;
            @include mid-full;
        }
    }
}
