.react-tabs {
    &__tab-list {
        font: {
            family: $font-feature;
            size: 20px;
        }
        width: 100%;
        overflow: hidden;
        margin-bottom: 35px;
        &:after {
            content: '';
            display: block;
            width: 100%;
            background: #848484;
            height: 1px;
            position: relative;
            z-index: 1;
            float: none;
            display: block;
            overflow: hidden;
        }
        &--fill-space {
            display: flex;
            position: relative;
            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
            }
            .react-tabs__tab {
                display: flex;
                flex-grow: 1;
                flex-basis: 0;
                flex-direction: column;
                text-align: center;
            }
        }
    }
    &__tab {
        transition: color .2s $easeInOutCirc;
        cursor: pointer;
        display: block;
        color: rgba(#4a4a4a, .5);
        float: left;
        margin-right: 40px;
        &:last-child {
            margin-right: 0;
        }
        &:after {
            transition: transform .3s $easeInOutCirc;
            content: '';
            display: block;
            width: 100%;
            height: 3px;
            background: $color-brand;
            margin-top: 10px;
            transform: translateY(4px);
            position: relative;
            z-index: 2;
        }
        &--selected {
            color: $color-base;
            &:after {
                transform: translateY(1px);
            }
            &:hover {
                color: $color-base;
            }
        }
    }
}
