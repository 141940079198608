.content-block-quote {
    width: span(8);
    margin: 0 auto;
    @media (max-width: $breakpoint-mid) {
        width: span(10);
    }
    @media (max-width: $breakpoint-small) {
        width: calc(100% - 60px);
    }
    &__body {
        position: relative;
        font-size: 27px;
        font-style: italic;
        font-weight: $font-weight-medium;
        line-height: 1.4em;
        margin-bottom: 1em;
        @media (max-width: $breakpoint-small) {
            font-size: 20px;
        }
    }
    &__highlight {
        position: absolute;
        display: block;
        width: 2px;
        height: 100%;
        position: absolute;
        left: -40px;
        top: 0;
        @media (max-width: $breakpoint-small) {
            left: -30px;
        }
    }
    &__author {
        font-size: 16px;
        line-height: 1.4em;
        &-name,
        &-title {
            display: block;
        }
    }
}
