.agile-overview {
    &__diagram,
    .headline__description {
        padding: {
            left: 5%;
            right: 5%;
        }
    }
    &__diagram {
        padding: {
            top: 45px;
            bottom: 30px;
        }
    }
    &__inner {
        @media (max-width: $breakpoint-small) {
            padding: 33px;
            @media (max-width: $breakpoint-small) {
                padding-bottom: 0;
            }
        }
    }
}
