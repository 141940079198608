.big-quote {
    margin: 150px 0 110px;
    position: relative;
    @media (max-width: $breakpoint-mid) {
        margin: 60px 0;
    }
    &:before {
        content: '';
        display: block;
        width: span(11, false);
        height: 100%;
        background: #f4f4f5;
        position: absolute;
        z-index: 1;
        top: 35px;
        left: auto;
        right: -30px;
        @media (max-width: $breakpoint-mid) {
            display: none;
            content: none;
        }
    }
    &__inner {
        overflow: hidden;
    }
    &__body {
        width: 49%;
        float: right;
        padding-top: 90px;
        padding-bottom: 25px;
        padding-right: .5em;
        position: relative;
        z-index: 3;
        @media (max-width: $breakpoint-mid) {
            width: 100%;
            float: none;
            padding: 15px 35px;
            transform: none;
        }
    }
    &__image {
        width: 50.65%;
        float: left;
        position: relative;
        z-index: 2;
        left: -30px;
        @media (max-width: $breakpoint-mid) {
            width: 100%;
            float: none;
            left: 0;
        }
    }
    &__cta {
        padding-top: 40px;
        .button {
            padding-bottom: 0;
        }
        @media (max-width: $breakpoint-mid) {
            padding-top: 15px;
        }
    }
    &__quote {
        font-size: 28px;
        font-weight: $font-weight-medium;
        line-height: 1.3em;
        margin-bottom: 40px;
        position: relative;
        text-shadow: 1px 1px rgba(#f4f4f5, .5);
        &:before {
            font: {
                family: $font-feature;
                size: 80px;
            }
            color: $color-brand;
            opacity: .5;
            position: absolute;
            text-shadow: none;
            content: "\201C";
            top: -25px;
            left: 0;
            @media (max-width: $breakpoint-mid) {
                font-size: 48px;
                left: -30px;
                top: 5px;
            }
        }
        @media (max-width: $breakpoint-mid) {
            font-size: 28px;
            line-height: 1.25em;
            margin-bottom: 20px;
            margin: .5em 0;
        }
        @media (max-width: $breakpoint-small) {
            font-size: 25px;
        }
    }
    &__author {
        font-size: 18px;
        &-name {
            margin-bottom: 6px;
            font-size: 16px;
            font-weight: bold;
            &:before {
                content: '- ';
            }
        }
    }
}
