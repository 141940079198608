.site-footer {
    padding: 40px 0;
    @media (max-width: $breakpoint-small) {
        padding: {
            left: 20px;
            right: 20px;
        }
    }
    &__header {
        margin-bottom: 15px;
    }
    &__section {
        @media (max-width: $breakpoint-mid) {
            margin-bottom: 35px;
        }
        @media (max-width: $breakpoint-small) {
            margin-bottom: 20px;
        }
    }
    &__utility-menu {
        border-top: 1px solid #555;
        padding-top: 13px;
        margin-top: 20px;
        max-width: 220px;
        @media (max-width: $breakpoint-small) {
            max-width: none;
        }
    }
}
