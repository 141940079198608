.results-hero {
    .canvas {
        padding-top: 85px;
        padding-bottom: 120px;
        @media (max-width: $breakpoint-mid) {
            padding-bottom: 40px;
        }
        @media (max-width: $breakpoint-small) {
            padding-top: 30px;
        }
    }
}
