.contact-newsletter {
    padding-top: 50px;
    @media (max-width: $breakpoint-small) {
        padding-top: 10px;
    }
    &__images {
        display: flex;
        justify-content: center;
        @media (max-width: $breakpoint-small) {
            width: calc(100% + 70px);
            margin-left: -35px;
        }
        > img {
            width: 267px;
            height: 339px;
            margin: 0 10px;
            @media (max-width: $breakpoint-small) {
                margin: 0 2px;
            }
        }
    }
    .signup {
        padding-top: 50px;
        width: 60%;
        margin: 0 auto;
        @media (max-width: $breakpoint-mid) {
            width: 100%;
        }
        &__input {
            border: none;
            border-bottom: 1px solid $color-keyline;
            font-size: 18px;
            color: $color-base;
            text-align: center;
            margin-bottom: 45px;
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active  {
                -webkit-box-shadow: 0 0 0 30px $color-canvas-alt inset !important;
            }
            &::placeholder {
                color: rgba($color-base, .5);
            }
        }
        &__submit {
            position: relative;
            background: $color-brand;
            color: $color-canvas;
            border-radius: 4px;
            padding-left: 4em;
            padding-right: 4em;
        }
        &__message {
            position: relative;
        }
    }
}
