.case-study-list {
    $block: &;
    padding: 10px 0;
    @media (max-width: $breakpoint-mid) {
        width: 80%;
        margin: 0 auto;
    }
    @media (max-width: $breakpoint-small) {
        width: 100%;
    }
    &__group {
        display: flex;
        justify-content: space-between;
        padding: 60px 0;
        @media (max-width: $breakpoint-large) {
            padding: 15px 0;
        }
        &--with-subgroups {
            flex-direction: row;
            @media (max-width: $breakpoint-mid) {
                flex-direction: column;
            }
        }
    }
    &__sub-group {
        display: flex;
        flex-direction: column;
        &--of-1 {
            width: calc(50% + #{$layout-gutter});
        }
        &--of-1,
        &--of-2 {
            @media (max-width: $breakpoint-large) {
                width: 50%;
                padding: 0 15px;
            }
            @media (max-width: $breakpoint-mid) {
                width: 100%;
                padding: 10px 15px;
            }
            @media (max-width: $breakpoint-small) {
                padding: 10px 0;
            }
        }
        &--of-1 + &--of-1,
        &--of-2 {
            width: 40%;
            @media (max-width: $breakpoint-mid) {
                width: calc(100% + 80px);
                margin-left: -40px;
            }
        }
    }
    .capabilities {
        background-color: #f6f6f6;
    }
}
