.case-study-tile {
    $block: &;
    display: flex;
    flex-direction: column;
    position: relative;
    color: $color-base;
    &--tile {
        padding: 35px 40px;
        & + #{$block}--tile {
            #{$block}__body:before {
                transition: opacity .25s $easeInOutQuad;
                content: '';
                display: block;
                border-top: 1px solid #e5e5e5;
                position: absolute;
                top: -35px;
                width: 100%;
                opacity: 1;
            }
            &:hover #{$block}__body:before {
                opacity: 0;
            }
        }
        &:hover + #{$block}--tile #{$block}__body:before {
            opacity: 0;
        }
    }
    &--rowWithImage {
        flex-direction: row-reverse;
        justify-content: space-between;
        flex-basis: 0;
        flex-grow: 1;
        @media (max-width: $breakpoint-mid) {
            flex-direction: column;
        }
    }
    &__preview {
        transition: transform .25s $easeInOutQuad;
        position: relative;
        transform: translateY(0);
        #{$block}--tileWithImage:hover & {
            @media (min-width: $breakpoint-mid) {
                transform: translateY(-7px);
            }
        }
        #{$block}--rowWithImage & {
            transition: transform .25s $easeInOutQuad;
            width: span(7, false);
            padding: 80px 0 50px;
            @media (max-width: $breakpoint-mid) {
                width: 100%;
            }
        }
        #{$block}--rowWithImage:hover & {
            @media (min-width: $breakpoint-mid) {
                transform: translateX(10px);
            }
        }
    }
    &__preview-image {
        transition: transform .25s $easeInOutQuad;
        position: relative;
        z-index: 1;
        &--top {
            margin: 0 auto;
            top: -20px;
        }
        &--right {
            right: -50px;
        }
        #{$block}--foodee & {
            @media (min-width: $breakpoint-mid) {
                margin: 0 24px;
            }
        }
        #{$block}--foodee & {
            @media (min-width: $breakpoint-mid) {
                margin: 0 24px;
            }
        }
        #{$block}--tileWithImage:hover & {
            @media (min-width: $breakpoint-mid) {
                transform: translateY(-15px);
            }
        }
        #{$block}--rowWithImage & {
            max-height: 320px;
            left: -60px;
        }
        #{$block}--rowWithImage:hover & {
            @media (min-width: $breakpoint-mid) {
                transform: translateX(-15px);
            }
        }
        &-actual {
            #{$block}--rowWithImage & {
                max-height: 240px;
            }
        }
    }
    &__preview-background-image {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: .3;
    }
    &__body {
        position: relative;
        z-index: 2;
        #{$block}--rowWithImage & {
            padding-top: 5px;
            width: span(4, false);
            @media (max-width: $breakpoint-mid) {
                width: 100%;
            }
        }
    }
    &__branded-screen {
        transition: opacity .25s $easeInOutQuad;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        #{$block}--tile:hover & {
            opacity: 1;
        }
    }
    &__title {
        font-size: 20px;
        font-weight: $font-weight-medium;
        line-height: 1em;
        margin: 0 0 18px;
    }
    &__name,
    &__category {
        display: block;
    }
    &__name {
        font-size: 28px;
        transition: color .25s $easeInOutQuad;
        margin-bottom: 5px;
        #{$block}--tile:hover & {
            color: $color-canvas !important;
        }
    }
    &__category {
        transition: all .25s $easeInOutQuad;
        opacity: 1;
        #{$block}--tile:hover & {
            color: $color-canvas;
            opacity: .5;
        }
    }
    &__description {
        transition: all .25s $easeInOutQuad;
        font-size: 28px;
        font-weight: $font-weight-medium;
        margin: 0;
        line-height: 1.3em;
        #{$block}--tileWithImage:not(:hover) &,
        #{$block}--rowWithImage:not(:hover) & {
            color: $color-base !important;
        }
        #{$block}--tile & {
            font-size: 18px;
            font-weight: 400;
            opacity: .5;
            color: #151515;
        }
        #{$block}--tile:hover & {
            color: $color-canvas;
            opacity: 1;
        }
    }
    &__preview {
        margin-bottom: 14px;
    }
}
