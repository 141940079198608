.u-accessible-hide {
    @include accessible-hide;
}

.u-clearfix {
    @include clearfix;
}

.u-block {
    display: block;
}

.u-reverse {
    color: $color-canvas;
    & * {
        color: $color-canvas;
        fill: $color-canvas;
    }
    .button {
        color: $color-canvas;
        &--underline {
            &:after {
                background: $color-brand;
            }
        }
    }
}

.u-unreverse {
    color: inherit;
    & * {
        color: inherit;
        fill: inherit;
    }
    .button {
        color: inherit;
        transition: none;
        &--underline {
            &:after {
                background: $color-brand;
            }
        }
    }
}

.u-overflow-hidden {
    overflow: hidden;
}
