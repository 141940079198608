.columns {
    $block: &;
    @include makeup-gutters;
    @include clearfix;
    overflow: hidden;
    &__item {
        padding: $layout-gutter / 2;
        @for $i from 1 through $layout-columns {
            #{$block}--#{$i} & {
                @include span($layout-columns / $i);
                float: left;
                @include mid-full;
            }
        }
    }
}
