.main-nav {
    @media (max-width: $breakpoint-mid) {
        transition: all .5s $easeInOutQuad;
        pointer-events: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-canvas;
        padding-top: 7em;
        opacity: 0;
        z-index: 0;
        * {
            pointer-events: none;
        }
        .has-mobile-nav & {
            opacity: 1;
            pointer-events: auto;
            z-index: 100;
            * {
                pointer-events: auto;
            }
        }
    }
    &__item {
        display: inline-block;
        @media (max-width: $breakpoint-mid) {
            display: block;
            display: flex;
            margin-bottom: 1.5em;
            .has-mobile-nav & {
                //creates grey background, however, it does for every item
                // background: #F6F6F6;
            }
        }
    }
    &__logo {
        @media (max-width: $breakpoint-mid) {
            position: absolute;
            top: 20px;
            left: 33px;
        }
        @media (max-width: $breakpoint-small) {
            top: 15px;
        }
    }
    &__close {
        position: absolute;
        top: 15px;
        right: 37px;
        z-index: 90;
        @media (max-width: $breakpoint-small) {
            top: 10px;
        }
    }
    .button:not(.button--link):not(.button--symantic) {
        margin-left: 22px;
        @media (max-width: $breakpoint-mid) {
            display: block;
            margin: 1em auto 0;
            text-align: center;
            min-width: 12em;
        }
        @media (max-width: $breakpoint-small) {
            width: calc(100% - 64px);
        }
    }
    .button--link {
        @media (max-width: $breakpoint-mid) {
            padding: {
                left: 10px;
                right: 10px;
            }
        }
        @media (max-width: $breakpoint-mid) {
            display: block;
            width: 100%;
        }
        &:hover .dropdown {
            display: block;
        }
    }
    .dropdown {
        @media (max-width: $breakpoint-mid) {
            display: none;
            position: relative;
            width: 100%;
        }
        .has-mobile-nav & {
            opacity: 1;
            pointer-events: auto;
            z-index: 100;
            margin-bottom: 0px;
            li {
                pointer-events: auto;
                display: block;
                width: 100%;
                height: 50px;
                position: relative;
                padding-top: 20px;
            }
        }
    }
    .icon--dropdown {
        padding-left: 5px;
    }
}
