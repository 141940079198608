.flex-columns {
    @include makeup-gutters;
    display: flex;
    &__item {
        display: flex;
        flex: 1;
        padding: $layout-gutter / 2;
        @for $i from 1 through $layout-columns {
            &--take-#{$i} {
                flex: $i;
            }
        }
    }
}
