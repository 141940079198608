.circled-image {
    width: 120px;
    height: 120px;
    background: #f4f4f5;
    border-radius: 50%;
    position: relative;
    padding-top: 5px;
    padding-left: 5px;
    text-align: center;
    &__image {
        max-width: 80px;
        max-height: 80px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
