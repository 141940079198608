.contact-work {
    padding-bottom: 120px;
    @media (max-width: $breakpoint-mid) {
        padding-bottom: 20px;
    }
    &__case-studies {
        display: flex;
        width: calc(100% + 20px);
        margin-left: -10px;
        @media (max-width: $breakpoint-mid) {
            flex-direction: column;
            width: 100%;
            margin-left: 0;
        }
    }
    &__case-study {
        flex-grow: 1;
        flex-basis: 0;
        margin: 0 10px;
        @media (max-width: $breakpoint-mid) {
            display: block;
            margin: 0;
            margin-bottom: 30px;
        }
    }
    .case-study-tile {
        &__preview {
            height: 0;
            // padding: 0 0 85%;
            padding: 0 0 45%;
            @media (max-width: $breakpoint-large) {
                // padding-bottom: 95%;
            }
            @media (max-width: $breakpoint-mid) {
                height: inherit;
                padding: 20px 30px;
            }
        }
        &__preview-image {
            position: absolute;
            top: -20px;
            left: 7%;
            width: 86%;
            height: 95%;
            @media (max-width: $breakpoint-mid) {
                position: relative;
                top: -50px;
                margin-bottom: -50px;
            }
        }
        &__preview-image-actual {
            max-height: 100%;
            margin: 0 auto;
        }
        &__description {
            font-size: 22px;
            font-weight: 400;
        }
        &--modo {
            .case-study-tile__preview {
                @media (max-width: $breakpoint-mid) {
                    padding: 40px 0;
                }
            }
            .case-study-tile__preview-image {
                top: 15%;
                width: 96%;
                left: 2%;
                margin-bottom: 0;
            }
        }
    }
}
