.content-block-slider {
    &__inner {
        position: relative;
        &:before {
            content: '';
            display: block;
            width: 200vw;
            height: calc(100% - 60px);
            position: absolute;
            z-index: 0;
            left: -175vw;
            bottom: 0;
            background: $color-canvas-alt;
        }
        @media (max-width: $breakpoint-small) {
            padding: 0;
        }
        .l-container {
            @media (max-width: $breakpoint-small) {
                padding: 0;
            }
        }
    }
    .slider {
        overflow: hidden;
        padding: 0;
        padding-bottom: 60px;
        position: relative;
        z-index: 1;
        &:before {
            background: $color-canvas-alt;
            width: 100%;
            right: 180px;
            @media (max-width: $breakpoint-small) {
                right: 50px;
            }
        }
        @media (max-width: $breakpoint-small) {
            padding-bottom: 40px;
        }
        &__arrow {
            left: auto;
            right: 200px;
            @media (max-width: $breakpoint-small) {
                right: 60px;
                bottom: 16px;
            }
            &--next {
                right: 140px;
                @media (max-width: $breakpoint-small) {
                    right: 20px;
                }
            }
        }
        &__item {
            padding-right: 0;
        }
    }
}
