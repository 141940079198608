.results-logo-pool {
    .headline {
        padding-bottom: 40px;
        padding-left: 4%;
        padding-right: 4%;
        @media (max-width: $breakpoint-small) {
            padding-bottom: 20px;
        }
    }
    .logo-pool {
        display: flex;
        flex-flow: wrap;
        @media (max-width: $breakpoint-small) {
            justify-content: center;
        }
        &__item {
            width: 25%;
            display: flex;
            justify-content: center;
            padding: 40px 0;
            @media (max-width: $breakpoint-small) {
                width: 33.3333%;
                padding: 20px 0;
            }
        }
        &__logo {
            display: flex;
            align-self: center;
            max-height: 95px;
            flex-grow: 0;
            flex-shrink: 0;
            @media (max-width: $breakpoint-small) {
                max-width: 80px;
                max-height: 80px;
            }
        }
    }
}
