.utility-menu {
    @extend .t-accent;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: .8px;
    &__item {
        display: inline-block;
        margin-right: 10px;
        &:after {
            content: '';
            display: inline-block;
            width: 1px;
            height: 10px;
            background: #555;
            margin-left: 10px;
        }
        &:last-child {
            margin-right: 0;
            &:after {
                content: none;
                display: none;
            }
        }
    }
    &__link {
        text-decoration: underline;
    }
}
