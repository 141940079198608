.results-screenshots {
    $block: &;
    position: relative;
    top: -25px;
    @media (max-width: $breakpoint-mid) {
        padding: 0 0 40px;
    }
    &__item {
        transition: transform .9s $easeInOutQuad, opacity .9s $easeInOutQuad;
        max-width: none;
        border-radius: 3px;
        &--1 {
            width: 55%;
            opacity: 0;
            transform: translate(-40px, 50px);
            #{$block}.is-ready & {
                opacity: .4;
                transform: translate(0, 50px);
            }
            @media (max-width: $breakpoint-mid) {
                position: relative;
                left: 20px;
                width: 45%;
            }
            @media (max-width: $breakpoint-small) {
                left: -20px;
                width: 50%;
            }
        }
        &--2 {
            position: absolute;
            width: 103.5%;
            top: 155px;
            right: -26%;
            transform: translateX(40px);
            opacity: 0;
            #{$block}.is-ready & {
                opacity: .98;
                transform: translateX(0);
            }
            @media (max-width: $breakpoint-mid) {
                top: calc(50% + 30px);
                right: 0;
                width: 75%;
                float: right;
                margin-top: -25%;
            }
            @media (max-width: $breakpoint-small) {
                width: 85%;
                right: -50px;
            }
        }
    }
}
