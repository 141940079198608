.case-study-hero {
    @extend .u-reverse;
    background-size: cover;
    background-color: #333;
    position: relative;
    background-position: 50% 50%;
    &:before {
        content: '';
        display: block;
        background: rgba(37,37,37,0.7);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &__body {
        padding-top: 260px;
        padding-bottom: 90px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        @media (max-width: $breakpoint-mid) {
            padding-top: 150px;
        }
        @media (max-width: $breakpoint-small) {
            padding-top: 40px;
            padding-bottom: 40px;
            flex-direction: column;
        }
    }
    &__main-info {
        width: span(6);
        @media (max-width: $breakpoint-small) {
            width: 100%;
            margin-bottom: 40px;
        }
    }
    &__supplementary-content {
        width: span(3, false);
        margin-right: span(1, false);
        @media (max-width: $breakpoint-mid) {
            width: span(5);
            margin: 0;
        }
        @media (max-width: $breakpoint-small) {
            width: 100%;
        }
    }
    &__headline {
        @extend .t-h2;
        line-height: 1.2em;
        margin: 0;
        font-family: $font-feature;
    }
    &__url {
        @include hover-focus-underline;
        font-size: 18px;
        margin-top: 35px;
        display: inline-block;
    }
    &__keyline {
        width: 60px;
        height: 2px;
        background: $color-brand;
        margin-bottom: 24px;
    }
    &__scope {
        font-size: 18px;
    }
    &__scope-item {
        margin-bottom: 10px;
    }
}
