.popover {
    transition: all .25s ease-in-out;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-5px);
    &.is-visible {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0px);
    }
}
