.body {
    width: 100vw;
    overflow: hidden;
    &.has-mobile-nav {
        @media (max-width: $breakpoint-mid) {
            height: 100vh;
            overflow: hidden;
        }
    }
}
