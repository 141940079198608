.masthead {
    $block: &;
    &--reverse.is-at-top {
        @extend .u-reverse;
        .has-mobile-nav & {
            @extend .u-unreverse;
        }
        .dropdown__label {
           color: #3B3B3A; 
        }
    }
    &__spacer {
        height: 130px;
        @media (max-width: $breakpoint-mid) {
            height: 64px;
        }
        @media (max-width: $breakpoint-small) {
            height: 54px;
        }
    }
    &__actual {
        transition: background .1s ease-in-out;
        overflow: hidden;
        padding: 45px 0;
        position: relative;
        position: fixed;
        background: rgba(255, 255, 255, 1);
        width: 100%;
        z-index: 100;
        @media (max-width: $breakpoint-mid) {
            padding: 20px 0;
        }
        @media (max-width: $breakpoint-small) {
            padding: 15px 0;
        }
        .has-mobile-nav & {
            overflow: visible;
        }
        .is-at-top & {
            background: rgba(0, 0, 0, .0);
        }
        .has-spacer & {
            transition: none;
        }
    }
    &__logo {
        float: left;
        width: 91px;
        height: 24px;
        transform: translateY(5px);
        * {
            transition: fill .1s ease-in-out;
        }
        @media (max-width: $breakpoint-mid) {
            transform: none;
        }
    }
    &__nav {
        float: right;
    }
    &__hamburger {
        float: right;
    }
}
