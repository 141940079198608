.about-opportunity {
    display: flex;
    flex-direction: row;
    padding: 125px 0 150px;
    @media (max-width: $breakpoint-mid) {
        flex-direction: column;
        padding: 40px 0;
    }
    &__body {
        @extend .t-large-body;
        width: span(5);
        margin-left: auto;
        @include mid-full;
    }
    &__photos {
        width: span(6.25, false);
        @media (max-width: $breakpoint-mid) {
            width: 75%;
            margin: 0 auto 25px;
        }
        @media (max-width: $breakpoint-small) {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}
