.text-input {
    &__input {
        border: none;
        border-bottom: 1px solid $color-keyline;
        display: block;
        width: 100%;
        padding: 10px 0;
        &:focus {
            outline: 0;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
    }
}

.careers-form .text-input {
    &__input {
        background-color: #F3F3F4;
        border: none;
        border-bottom: 1px solid $color-keyline;
        display: block;
        width: 100%;
        padding: 10px 0;
        &:focus {
            outline: 0;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
    }
}