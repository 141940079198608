.progress-bar {
    display: flex;
    height: 24px;
    margin-bottom: 28px;

    > span {
        font-family: $font-feature;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;

        &:first-child {
            width: 24px;
            text-align: left;
        }

        &:last-child {
            color: #c4c4c4;
        }
    }

    > div {
        display: inline-block;
        width: 118px;
        height: 4px;
        margin: 10px 6px 10px 4px;
    }   
}
