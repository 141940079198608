.capabilities-intro {
    display: table;
    @media (max-width: $breakpoint-mid) {
        display: flex;
        flex-flow: wrap;
    }
    &__item {
        display: table-cell;
        vertical-align: middle;
        padding-right: 40px;
        margin: auto;
        text-align: center;
        @media (max-width: $breakpoint-mid) {
            display: block;
            width: 50%;
            padding: 33px 10px;
        }
        &:last-child {
            padding-right: 0;
        }
    }
    &__title {
        text-transform: uppercase;
        letter-spacing: 0.05em;
        color: #3B3B3A;
        font-size: 15px;
        margin-bottom: 2%;
    }

    /* for web */
    @media (min-width: $breakpoint-mobile) {
        &__title {
            font-size: 18px;
        }
    }

    &__description {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: #000000;
    }
    &__logo {
        text-align: center;
        margin: auto;
        position: relative;
        max-height: 75px;
        @media (max-width: $breakpoint-mid) {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            padding-left: 5%
        }
    }
    &__cta {
        padding-top: 18px;
    }
}
