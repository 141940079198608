.index {
    &__item {
        padding: 0 60px 15px;
        margin-bottom: 15px;
        position: relative;
        border-bottom: 1px solid #e5e5e5;
        &:last-child {
            border-bottom: none;
        }
        @media (max-width: $breakpoint-mid) {
            padding-left: 50px;
        }
    }
    &__number {
        position: absolute;
        top: 2px;
        left: 0;
        color: $color-brand;
        font: {
            family: $font-feature;
            size: 18px;
        }
    }
    &__title {
        font: {
            family: $font-feature;
            size: 20px;
        }
        margin-bottom: 5px;
    }
    &__description {
        font-size: 18px;
        line-height: 1.4;
    }
}
