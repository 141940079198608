@mixin button-label-underline {
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: var(--underline-color, $color-brand);
        margin-top: 4px;
    }
}

.button {
    $block: &;
    @extend .t-accent;
    transition: all .1s ease-in-out;
    text-transform: uppercase;
    color: $color-base;
    letter-spacing: .8px;
    display: inline-block;
    padding: 8px 22px;
    border-radius: 4px;
    border: 1px solid $color-brand;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    &:hover,
    &:focus {
        background-color: $color-brand;
        color: $color-canvas;
    }
    &__spinner {
        transition: transform .5s $easeInOutQuart;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -200%);
        #{$block}.is-working & {
            transform: translate(-50%, -50%);
        }
    }
    &__label {
        transition: transform .5s $easeInOutQuart;
        position: relative;
        z-index: 2;
        display: inline-block;
        #{$block}.is-working & {
            transform: translateY(200%);
        }
    }
    &--primary {
        background-color: $color-brand;
        color: $color-canvas;
        &:hover {
            background: transparent;
            color: $color-base;
        }
    }
    &--link {
        background: none;
        border-color: transparent;
        color: #555;
        padding: {
            left: 0;
            right: 0;
        }
        margin: 0 22px;
        &:hover,
        &:focus {
            background-color: transparent;
            color: #555;
        }
        #{$block}__label {
            &:after {
                transition: all .2s ease-in-out;
                content: '';
                display: block;
                transform: translateY(3px);
                width: 100%;
                height: 12px;
                background: $color-brand;
                opacity: 0;
                position: absolute;
                bottom: -4px;
                z-index: -1;
            }
        }
        &:hover {
            #{$block}__label:after {
                opacity: .5;
                transform: translateY(0px);
            }
        }
        &.is-active {
            #{$block}__label:after,
            &:hover #{$block}__label:after {
                transform: translateY(0px);
                opacity: 1;
                height: 2px;
            }
        }
    }
    &--underline {
        @include button-label-underline;
        border: 0;
        padding: {
            left: 0;
            right: 0;
        }
        &:hover,
        &:focus {
            background: transparent;
            color: $color-base;
        }
    }
    &--icon {
        width: 52px;
        height: 52px;
        border-radius: 52px;
        text-align: center;
        cursor: pointer;
        position: relative;
        line-height: 0;
        #{$block}__icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &--symantic {
        padding: initial;
        border: initial;
        background: initial;
        color: initial;
        &:hover,
        &:focus {
            background: initial;
            color: initial;
        }
    }
    &--disabled {
        color: $color-base;
        border-color: $color-base;
        opacity: .2;
        cursor: not-allowed;
        &:hover,
        &:focus {
            color: $color-base;
            border-color: $color-base;
            background: inherit;
        }
    }
    .spinner {
        transition: all .25s ease-in-out;
        border-color: $color-brand;
        border-left-color: transparent;
    }
    &:hover .spinner,
    &:focus .spinner {
        border-color: $color-canvas;
        border-left-color: transparent;
    }
}

button {
    @extend .button;
    cursor: pointer;
}
