.page {
    &--how {
        .callout--full .callout__cta {
            margin-top: 30px;
            @media (max-width: $breakpoint-small) {
                margin-top: 10px;
            }
        }
    }
    &__cta {
        text-align: center;
    }
}
