.content-block-content {
    $block: &;
    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media (max-width: $breakpoint-mid) {
            flex-direction: column;
        }
        #{$block}.has-images--1:nth-of-type(even) & {
            flex-direction: row-reverse;
        }
    }
    &__main {
        position: relative;
        z-index: 1;
        width: span(9);
        @media (max-width: $breakpoint-mid) {
            width: 100%;
        }
        & > * {
            position: relative;
            z-index: 2;
        }
        #{$block}.has-images & {
            width: span(6);
            @media (max-width: $breakpoint-mid) {
                width: 100%;
            }
        }
        #{$block}.has-images--1 & {
            width: span(5, false);
            @media (max-width: $breakpoint-mid) {
                width: 100%;
            }
        }
    }
    &__images {
        width: span(6, false);
        position: relative;
        z-index: 0;
        @media (max-width: $breakpoint-mid) {
            width: 100%;
        }
        & > .l-container {
            @media (max-width: $breakpoint-small) {
                padding: 0;
            }
        }
    }
    &__lonely-image {
        margin: 0 auto;
    }
    &__title {
        font-size: 44px;
        font-weight: $font-weight-medium;
        margin: 0 0 .5em;
    }
    &__body {
        font-size: 18px;
        line-height: 1.45em;
        p {
            margin-bottom: 1.5em;
        }
    }
    &__slider {
        position: relative;
        right: -80px;
        z-index: 0;
        @media (max-width: $breakpoint-mid) {
            right: 0;
        }
        @media (max-width: $breakpoint-small) {
            padding-top: 20px;
        }
        &:before {
            height: 70%;
            width: 150%;
            left: 68px;
            right: auto;
            top: auto;
            bottom: 0;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -500%;
            height: 100%;
            width: 500%;
            background: white;
            pointer-events: none;
            z-index: 2;
            @media (max-width: $breakpoint-mid) {
                display: none;
            }
        }
    }
}
