.chat-bubble {
    &__panel {
        @extend .u-reverse;
        position: absolute;
        bottom: 80px;
        right: 0;
        background: $color-brand;
        padding: 20px 30px;
        width: 20em;
        border-radius: 3px;
        z-index: 10;
        &:after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            background: $color-brand;
            position: absolute;
            bottom: -9px;
            right: 18px;
            transform: rotate(-45deg);
        }
    }
    &__button {
        transition: all .3s $easeInOutSine;
        transform: scale(1);
        &:hover,
        &:focus {
            background: $color-brand;
            color: $color-canvas;
            transform: scale(1.15);
            outline: 0;
        }
    }
    .button--underline:after { // Need to upd the specificity, thus breaking nesting
        background: $color-canvas;
    }
    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 6px;
    }
    &__content {
        position: relative;
        z-index: 2;
    }
    &__cta {
        padding-top: 5px;
    }
    &__description {
        font-size: 16px;
        letter-spacing: .5px;
    }
}
