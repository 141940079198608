.content-block-expose {
    position: relative;
    color: $color-canvas;
    &__background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        z-index: 0;
        opacity: .4;
        filter: grayscale(100%);
        mix-blend-mode: multiply;
    }
    &__body {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        @media (max-width: $breakpoint-mid) {
            flex-direction: column;
            align-items: center;
            padding-bottom: 60px;
        }
    }
    &__title {
        font-size: 38px;
        font-weight: $font-weight-medium;
        line-height: 1.3em;
        width: 44%;
        padding-top: 40px;
        font-family: $font-feature;
        @media (max-width: $breakpoint-mid) {
            width: span(10);
        }
        @media (max-width: $breakpoint-small) {
            width: 100%;
            font-size: 30px;
        }
        &:before {
            content: '';
            display: block;
            height: 2px;
            width: 60px;
            background: $color-canvas;
            margin-bottom: 30px;
        }
    }
    &__foreground-image {
        width: span(7);
        @media (max-width: $breakpoint-mid) {
            width: span(8);
            margin-top: 20px;
        }
        @media (max-width: $breakpoint-small) {
            width: span(10);
        }
    }
    &__foreground-image-actual {
        margin: auto;
    }
}
