.contact-hero {
    .canvas {
        @media (max-width: $breakpoint-small) {
            padding: 40px 0;
        }
    }
    .hero__inner {
        padding: 55px $layout-gutter;
        justify-content: space-between;
        @media (max-width: $breakpoint-mid) {
            padding: 0 $layout-gutter;
        }
    }
    .hero__text {
        width: span(5);
        @media (max-width: $breakpoint-mid) {
            width: 100%;
            margin-bottom: 30px;
        }
    }
    .hero__gallery {
        width: 54.25%;
        padding: 0;
        margin: 0;
        @media (max-width: $breakpoint-mid) {
            width: 100%;
        }
    }
    &__image {
        margin: 0 auto;
    }
}
