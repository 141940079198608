.contact-main {
    padding: 80px 0;
    display: flex;
    justify-content: space-between;
    @media (max-width: $breakpoint-mid) {
        flex-direction: column;
    }
    @media (max-width: $breakpoint-small) {
        padding: 50px 0;
    }
    a {
        @include hover-focus-underline;
        color: inherit;
    }
    &__location {
        width: span(5);
        font-weight: $font-weight-medium;
        @media (max-width: $breakpoint-mid) {
            width: 100%;
        }
    }
    &__location-title {
        font-size: 28px;
        margin: 0 0 40px;
    }
    &__location-button {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: $breakpoint-small) {
            display: flex;
            justify-content: center;
        }
    }
    &__form {
        width: span(6);
        @media (max-width: $breakpoint-mid) {
            width: 100%;
        }
    }
    &__contact-info {
        font-size: 22px;
        opacity: .5;
        @media (max-width: $breakpoint-mid) {
            font-size: 14px;
        }
    }
    .contact-info__address3 {
        margin-bottom: 1.5em;
    }
    &__map-container {
        padding-top: 50px;
        @media (max-width: $breakpoint-mid) {
            display: flex;
            justify-content: center;
            margin-bottom: 50px;
        }
    }
    .enemies {
        &__left {
            width: 52%;
            @media (max-width: $breakpoint-small) {
                width: 100%;
            }
        }
        &__right {
            @media (max-width: $breakpoint-small) {
                display: flex;
                justify-content: center;
            }
        }
        &--one-only {
            justify-content: flex-start;
        }
    }
    
    button[type="submit"] {
        margin-top: 15px;
        width: 140px;
    }
}
