.about-outline {
    overflow: hidden;
    padding-bottom: 60px;
    &__intro {
        width: span(4);
        float: left;
        @include mid-full;
        @media (max-width: $breakpoint-mid) {
            margin-bottom: 40px;
        }
    }
    &__index {
        width: span(7);
        float: right;
        @include mid-full;
    }
    &__title {
        margin: 0 0 25px;
    }
    &__description {
        font-size: 18px;
        line-height: 28px;
    }
}
