.contact-forms {
    .enemies {
        &__left {
            width: 52%;
            @media (max-width: $breakpoint-small) {
                width: 100%;
            }
        }
        &__right {
            @media (max-width: $breakpoint-small) {
                display: flex;
                justify-content: center;
            }
        }
        &--one-only {
            justify-content: flex-start;
        }
    }
    .react-tabs {
        &__tab-list {
            @media (max-width: $breakpoint-small) {
                margin-bottom: 0;
            }
        }
    }
    button[type="submit"] {
        margin-top: 15px;
        width: 140px;
    }
}
