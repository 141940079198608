.canvas {
    background: $color-canvas;
    &--alt {
        background: $color-canvas-alt;
    }
    &--gradient {
        background: $gradient-canvas;
        &--dark {
            background: $gradient-canvas-darK;
        }
    }
    &--offset {
        position: relative;
        background: none;
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: calc(100% - 190px);
            left: 0;
            bottom: 0;
            @media (max-width: $breakpoint-small) {
                height: calc(100% - 90px);
            }
        }
        & > * {
            position: relative;
            z-index: 1;
        }
        &.canvas--alt:before {
            background: $color-canvas-alt;
        }
    }
    &--background-photo {
        @extend .u-reverse;
        background-size: cover;
        background-position: 50% 50%;
        &.tick {
            padding: {
                top: 30px;
                bottom: 30px;
            }
        }
        .react-tabs {
            &__tab {
                color: rgba(255, 255, 255, .7);
                &:hover {
                    color: rgba(255, 255, 255, .7);
                }
                &--selected {
                    color: $color-canvas;
                    &:hover {
                        color: $color-canvas;
                    }
                }
            }
        }
    }
}
