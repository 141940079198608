.expose {
    @extend .u-reverse;
    position: relative;
    padding: 0 0 100px;
    min-height: 640px;
    width: 100%;
    &:before,
    &:after {
        display: none;
        left: 50%;
    }
    @media (max-width: $breakpoint-mid) {
        padding-bottom: 55px;
        padding-top: 60px;
        &:before,
        &:after {
            display: block;
        }
    }
    &__text {
        width: 44%;
        position: relative;
        z-index: 6;
        @media (max-width: $breakpoint-mid) {
            width: 100%;
        }
    }
    &__title {
        margin: 0 0 35px;
        padding-top: 100px;
        @media (max-width: $breakpoint-mid) {
            padding-top: 60px;
        }
        @media (max-width: $breakpoint-mid) {
            &:before,
            &:after {
                display: none;
            }
        }
    }
    &__description {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 1.15em;
    }
    &__images {
        position: absolute;
        top: -85px;
        right: 0;
        @media (max-width: $breakpoint-mid) {
            position: relative;
            top: 0;
            margin-bottom: 50px;
        }
    }
    &__screenshot {
        width: 303px;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 5px;
        box-shadow: 0 0 3px rgba(0, 0, 0, .1);
        overflow: hidden;
        @media (max-width: $breakpoint-mid) {
            width: 182px;
            width: 151px;
        }
        &:nth-child(1) {
            z-index: 4;
            right: 15px;
            @media (max-width: $breakpoint-mid) {
                position: relative;
                margin: 0 auto;
                right: 0;
            }
        }
        &:nth-child(2) {
            z-index: 3;
            width: 231px;
            transform: translate(130px, 64%);
            opacity: .95;
            @media (max-width: $breakpoint-mid) {
                width: 103px;
                transform: translate(101%, 85px);
                left: 50%;
            }
        }
        &:nth-child(3) {
            z-index: 2;
            width: 220px;
            transform: translate(-230px, 56%);
            opacity: .49;
            @media (max-width: $breakpoint-mid) {
                width: 117px;
                transform: translate(-190%, 75px);
                left: 50%;
            }
        }
        &:nth-child(4) {
            z-index: 1;
            width: 114px;
            transform: translate(60px, 65%);
            opacity: .08;
            filter: blur(6px);
            @media (max-width: $breakpoint-mid) {
                width: 100px;
                transform: translate(140%);
                left: 50%;
            }
        }
    }
    &__chat {
        position: absolute;
        bottom: -25px;
        width: 100%;
        z-index: 10 ;
    }
    .chat-bubble {
        position: absolute;
        bottom: 0;
        right: 0;
        @media (max-width: $breakpoint-mid) {
            right: 35px;
        }
    }
}
