.social {
    * {
        fill: #d8d8db;
    }
    &__site {
        display: inline-block;
        margin-right: 27px;
        @media (max-width: $breakpoint-mid) {
            margin-right: 33px;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    &__logo-svg {
        width: 20px;
        height: 20px;
        @media (max-width: $breakpoint-mid) {
            width: 24px;
            height: 24px;
        }
    }
}
