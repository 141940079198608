.home-hero {
    margin: 0 auto;
    position: relative;
    padding: {
        top: 137px;
        bottom: 3em;
        left: calc(20% - 150px);
        right: 0%;
    }

    @media (max-width: $breakpoint-mid) {
        padding: {
            top: 40px;
            bottom: 0;
            left: 33px;
            right: 33px;
        }
    }

    &__title {
        @extend .t-h1;
        max-width: 578px;
        margin-top: 0;
        margin-bottom: 39px;
        font-size: 48px;
        font-weight: 500;
        line-height: 63px;
        letter-spacing: 1px;
    }
    &__description {
        max-width: 601px;
        margin-bottom: 121px;
        font-size: 28px;
        line-height: 30px;

        &:before {
            content: '';
            display: block;
            width: 78px;
            height: 2px;
            background: $color-brand;
            margin-bottom: 45px;
        }

        @media (max-width: $breakpoint-mid) {
            margin-bottom: 51px;
        }
    }
    &__placeholder {
        position: absolute;
        width: 606px;
        height: 606px;
        left: calc(714px + 20% - 150px);
        top: 32px;
        z-index: -1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 606px;
            top: 0;
            width: 50vw;
            height: 606px;
            background: #fff;
            z-index: 1;
        }

        @media (max-width: $breakpoint-mid) {
            position: relative;
            height: calc(100% + 66px);
            width: calc(100% + 66px);
            top: 0;
            left: -33px;

            &:after {
                display: none;
            }
        }
    }
    &__image {
        position: relative;
        width: 606px;
        height: 606px;

        &.\--animate {
            animation: slide-in 0.5s;
        }

        @media (max-width: $breakpoint-mid) {
            width: 100vw;
            height: 100vw;
        }
    }
    &__features {
        display: inline-flex;
        height: 151px;
        padding-right: calc(15vw - 160px);
        background: #f3f3f4;

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: -25vw;
            width: 50vw;
            height: 151px;
            background: #f3f3f4;
            z-index: -1;

            @media (max-width: $breakpoint-mid) {
                height: 122px;
                left: calc(-50vw + 33px);
            }
        }

        @media (max-width: $breakpoint-mid) {
            position: relative;
            top: -30px;
            width: calc(100% - 148px);
            max-width: 255px;
            height: 122px;
        }

        @media (max-width: $breakpoint-small) {
            width: calc(100% - 148px);
            min-width: 186px;
        }


        > div, a {
            display: inline-block;
            width: 186px;
            min-width: 186px;
            height: 151px;
            padding-top: 39px;
            padding-right: 16px;
            box-sizing: content-box;
            margin: 0;

            @media (max-width: $breakpoint-mid) {
                height: 122px;
                padding-top: 24px;
            }

            @media (max-width: $breakpoint-small) {
                box-sizing: border-box;
            }

            &:first-child {
                display: none;
                font-weight: bold;

                > h3 {
                    max-height: 80px;
                    margin: 0;
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    line-height: 25px;
                }
            }

            > p, h4 {
                display: inline-block;
                margin: 0 0 14px 0;
                font-size: 18px;
                font-weight: bold;
                line-height: 19px;
                color: #000;
                &:first-child {
                    text-transform: uppercase;
                    border-bottom: 2px solid #f3f3f4;
                }

                &:last-child {
                    font-weight: normal;
                }

                &:only-child {
                    text-transform: uppercase;
                    border: none;
                }
            }

            > p {
                margin-bottom: 0;

                @media (max-width: $breakpoint-mid) {
                    font-size: 16px;
                }
            }

            @media (max-width: $breakpoint-mid) {
                display: none; 
            }

            &.\--active {
                @media (max-width: $breakpoint-mid) {
                    display: block; 
                }

                > h4:first-child {
                    border-bottom: 2px solid #da6666;
                }
            }

            &.\--inactive {
                p, h4 {
                    color: #c4c4c4;
                }
            }
        }
    }

    &__arrows {
        display: inline-flex;
        position: relative;
        width: 0;

        @media (max-width: $breakpoint-mid) {
            top: -40px; 

            &.\--with-bumper {
                top: -43px;
            }
        }


        &-inner {
            position: absolute;
            width: 400px;
            top: 40px;

            @media (max-width: $breakpoint-mid) {
                top: -20px; 
            }

            > button {
                border: none;

                @media (max-width: $breakpoint-mid) {
                    top: 54px;
                }

                &:hover, &:focus, &:active {
                    background: none;
                }

                &:focus {
                    outline: none;
                }

                &:first-child {
                    margin-left: 3vw;
                    margin-right: 3vw;

                    @media (max-width: $breakpoint-small) {
                        padding-left: 4vw;
                        padding-right: 2vw;
                    }
                }

                &:last-child {
                    @media (max-width: $breakpoint-small) {
                        padding-left: 2vw; 
                        padding-right: 4vw;
                    }
                }
            }
        }
    }
}

@keyframes slide-in {
    from {
        left: 100%;
    }

    to {
        left: 0;
    }
}
