@mixin checkered-tick($height: 30px, $top: 0px) {
    &:before,
    &:after {
        content: '';
        display: block;
        width: 1px;
        height: $height;
        left: 50%;
        z-index: 2;
        @media (max-width: $breakpoint-small) {
            height: 20px;
        }
    }
    &:before {
        background: #2b2b2b;
        top: $top - $height;
        @media (max-width: $breakpoint-small) {
            top: $top - 20px;
        }
    }
    &:after {
        background: #d8d8d8;
        top: $top;
    }
}

.tick {
    position: relative;
    &:before,
    &:after {
        content: '';
        display: block;
        width: 1px;
        height: 60px;
        background: #d8d8d8;
        position: absolute;
        top: -30px;
        left: 50%;
        @media (max-width: $breakpoint-small) {
            height: 40px;
            top: -20px;
        }
    }
    &--checkered {
        @include checkered-tick;
    }
    &--hide-mid {
        @media (max-width: $breakpoint-mid) {
            &:before,
            &:after {
                content: none;
                display: none;
            }
        }
    }
    &--hide-small {
        @media (max-width: $breakpoint-small) {
            &:before,
            &:after {
                content: none;
                display: none;
            }
        }
    }
    &--only-mid {
        &:before,
        &:after {
            display: none;
        }
        @media (max-width: $breakpoint-mid) {
            &:before,
            &:after {
                display: block;
            }
        }
    }
    &--only-small {
        &:before,
        &:after {
            display: none;
        }
        @media (max-width: $breakpoint-small) {
            &:before,
            &:after {
                display: block;
            }
        }
    }
}
