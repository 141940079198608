.contact-info {
    font-size: 14px;
    max-width: 23em;
    @media (max-width: $breakpoint-mid) {
        column-count: 2;
    }
    &__element {
        margin-bottom: .25em;
    }
    a {
        text-decoration: underline;
    }
}
