.enemies {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: $breakpoint-small) {
        flex-direction: column;
    }
    &__left {
        @media (max-width: $breakpoint-small) {
            margin-bottom: 10px;
        }
    }
    &__center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    &--one-only {
        justify-content: flex-end;
    }
}
