.super-hero {
    padding: 30px 0 105px;
    overflow: auto;
    @media (max-width: $breakpoint-mid) {
        padding: 15px 0 50px;
    }
    &__title {
        margin: 0;
        width: span(5);
        float: left;
        font-size: 120px;
        @media (max-width: $breakpoint-mid) {
            font-size: 66px;
        }
    }
    &__description {
        width: span(6);
        float: right;
        position: relative;
        padding-top: 12%;
        @media (max-width: $breakpoint-mid) {
            width: span(6.5);
            padding-top: 2%;
        }
        @media (max-width: $breakpoint-small) {
            margin-top: 28px;
            padding-top: 0;
        }
        &:before {
            width: 60px;
            height: 3px;
            margin-bottom: 25px;
        }
    }
    &__title,
    &__description {
        @media (max-width: $breakpoint-small) {
            width: 100%;
            float: none;
        }
    }
}
