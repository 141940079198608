.hamburger {
    height: 22px;
    width: 36px;
    position: relative;
    cursor: pointer;
    &:before,
    &:after {
        content: '';
    }
    &:before,
    &:after,
    &__center {
        background: #555;
        height: 2px;
        border-radius: 1.5px;
        width: 100%;
        position: absolute;
        left: 0;
        .masthead--reverse & {
            background: white;
        }
    }
    &:before {
        top: 0;
    }
    &__center {
        width: 28px;
        top: 50%;
        margin-top: -1px;
    }
    &:after {
        bottom: 0;
    }
    &:focus {
        outline: 0;
    }
}
