.slider {
    $block: &;
    position: relative;
    padding: 63px 0;
    @media (max-width: $breakpoint-mid) {
        padding: 40px 0 65px;
    }
    &:before {
        content: '';
        display: block;
        width: calc(100% - 70px);
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-image: linear-gradient(to left, #ffffff, #f0f0f0);
    }
    &__item {
        padding-right: 20px;
        &:focus {
            outline: 0;
        }
        img:focus {
            outline: 0;
        }
    }
    &__arrow {
        position: absolute;
        bottom: 32px;
        left: 20px;
        font-size: 30px;
        line-height: 0;
        &:focus {
            outline: 0;
        }
        &--next {
            left: 90px;
        }
        &__svg {
            width: 22px;
            display: block;
            &-svg {
                transition: fill .2s $easeInOutCirc;
            }
        }
        &.is-disabled {
            #{$block}--finite & {
                .slider__arrow__svg-svg {
                    fill: #d8d8db;
                }
            }
        }
    }
}

.slick {
    &-list {
        position: relative;
        z-index: 2;
    }
    &-track,
    &-slide {
        display: flex;
    }
    &__offsetter {
        width: 20vw;
    }
}
