.careers-venn {
    overflow: hidden;
    padding: 80px 0;
    @media (max-width: $breakpoint-mid) {
        padding: 50px 0;
    }
    &__text {
        width: span(6);
        float: left;
        @include mid-full;
    }
    &__title {
        padding-right: 1em;
    }
    &__diagram {
        width: span(5);
        float: right;
        @media (max-width: $breakpoint-mid) {
            width: 75%;
            margin: 45px auto 25px;
            float: none;
        }
        @media (max-width: $breakpoint-small) {
            width: 100%;
        }
    }
}
