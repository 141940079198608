.space {
    padding-bottom: 100px;
    @media (max-width: $breakpoint-small) {
        padding-bottom: 50px;
    }
    &--more {
        padding-bottom: 200px;
        @media (max-width: $breakpoint-mid) {
            padding-bottom: 100px;
        }
    }
    &--less {
        padding-bottom: 50px;
    }
    &--little {
        padding-bottom: 25px;
    }
    &--inside {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
