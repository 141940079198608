.careers-outline-item {
    display: flex;
    flex-direction: row;
    padding-bottom: 60px;
    @media (max-width: $breakpoint-mid) {
        padding: 50px 0;
    }
    &__text {
        width: 80%;
        @media (max-width: $breakpoint-mid) {
            width: 100%;
        }
    }
    &__title {
        padding-right: 1em;
    }
    &__points {
        padding-top: 2px;
        width: 25%;
        @media (max-width: $breakpoint-small) {
            width: 100%;
        }
    }
    &__body {
        display: flex;
        flex-direction: row;
        @media (max-width: $breakpoint-small) {
            flex-direction: column;
        }
    }
    &__description {
        width: 75%;
        padding-right: 7em;
        @media (max-width: $breakpoint-small) {
            width: 100%;
            margin-bottom: 18px;
            padding-right: 0;
        }
    }
}
