.content-block {
    padding: 60px 0;
    @media (max-width: $breakpoint-mid) {
        padding: 30px 0;
    }
    @media (max-width: $breakpoint-small) {
        padding: 20px 0;
    }
    &:last-of-type {
        padding-bottom: 0;
    }
    &--quote {
        padding-top: 30px;
    }
    &--quote:last-of-type {
        padding-bottom: 100px;
        @media (max-width: $breakpoint-mid) {
            padding: 30px 0;
        }
        @media (max-width: $breakpoint-small) {
            padding: 20px 0;
        }
    }
}
