.tabbed-showcase {
    display: flex;
    flex-direction: row;
    padding: 50px 0 85px;
    @media (max-width: $breakpoint-mid) {
        flex-direction: column;
        padding: 20px 0;
    }
    &__intro {
        width: 40%;
        padding-right: 20px;
        @include mid-full;
        @media (max-width: $breakpoint-mid) {
            margin-bottom: 25px;
        }
    }
    &__title {
        margin: 0 0 25px;
    }
    &__description {
        max-width: 19em;
        @media (max-width: $breakpoint-mid) {
            max-width: none;
        }
    }
    &__tabs {
        width: span(7, false);
        @include mid-full;
    }
}
