.spinner {
    animation: spin 1.5s linear 0s infinite;
    border: 3px solid rgba(255, 255, 255, .85);
    border-left-color: transparent;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: block;
    &--large {
        width: 40px;
        height: 40px;
    }
}
