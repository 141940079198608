.content-block-blockquote {
    text-align: center;
    font-size: 32px;
    line-height: 1.5em;
    font-weight: $font-weight-medium;
    font-family: $font-feature;
    @media (max-width: $breakpoint-small) {
        font-size: 26px;
    }
    &__body {
        padding: 0 2em;
        @media (max-width: $breakpoint-small) {
            padding: .5em;
        }
    }
    &__divider {
        width: 60px;
        height: 2px;
        margin: 30px auto 0;
        @media (max-width: $breakpoint-small) {
            margin-top: 15px;
        }
    }
}
