.capabilities-content-list {
    counter-reset: case-study;
    &__list {
        &__item {
            margin-bottom: 150px;
            counter-increment: case-study;
            padding-top: 10px;
            @media (max-width: $breakpoint-mid) {
                margin-bottom: 60px;
            }
            &:last-of-type {
                margin-bottom: 50px;
            }
            &:nth-of-type(odd) {
                @media (max-width: $breakpoint-mid) {
                    padding-left: 0;
                }
                .case-study-feature {
                    flex-direction: row-reverse;
                    @media (max-width: $breakpoint-mid) {
                        flex-direction: column;
                    }
                    &__body {
                        margin-left: 0;
                    }
                    &__imagery {
                        padding-top: 10px;
                        padding-left: 0px;
                        @media (max-width: $breakpoint-mid) {
                            padding-top: 10px;
                            margin-left: -20px;
                        }                    
                    }
                    &__title {
                        color: $color-brand;
                    }
                    &__cta {
                        color: $color-brand;
                    }
                    &__description {
                        margin-top: 20px;
                        margin-right: 80px; 
                        @media (max-width: $breakpoint-mid) {
                            margin-right: 35px;
                        }
                    }
                }
            }
            &:nth-of-type(even) {
                .case-study-feature {
                    flex-direction: row;
                    @media (max-width: $breakpoint-mid) {
                        flex-direction: column;
                    }
                    &__body {
                        margin-left: 80px;
                        @media (max-width: $breakpoint-mid) {
                            margin-left: 0px;
                        }    
                    }
                    &__imagery {
                        padding-top: 0px;
                        padding-right: 0px;     
                        @media (max-width: $breakpoint-mid) {
                            padding-top: 10px;
                            margin-left: -20px;
                        }                    
                    }
                    &__cta {
                        color: $color-brand;
                    }
                    &__description {
                        margin-top: 20px;
                        @media (max-width: $breakpoint-mid) {
                            margin-right: 35px;
                        }
                    }
                    
                }
            }
        }
    }
    &__cta {
        text-align: center;
        padding: 80px 0 100px;
        @media (max-width: $breakpoint-small) {
            padding: 60px $layout-gutter;
            .button {
                display: block;
            }
        }
    }
    .headline {
        &__title{
            margin-top: 70px;
            margin-bottom: 50px;
        }
    }
    // .button--underline {
    //     &:after {
    //         content: '';
    //         display: block;
    //         width: 100%;
    //         height: 2px;
    //         background: $color-brand;
    //         margin-top: 4px;
    //     }
    // }
}
